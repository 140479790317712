.message {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  justify-content: space-between;
  max-width: 80%;
  margin-left: 15px;
  margin-right: auto;
}

.message > section {
  display: flex;
  flex-direction: row;
  background-color: #f3f3f5;
  font-size: medium;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
  margin-left: 0px;
  margin-right: auto;
  justify-content: center;
  align-items: flex-end;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.message > section > div > p {
  margin: 5px;
}

.message__date {
  color: gray;
  position: absolute;
  font-size: 12px;
  bottom: -6px;
  left: 0;
  white-space: nowrap;
}

.message__date__outbound {
  right: 0;
  left: auto;
}

.message__sender {
  margin-left: auto;
  margin-right: 15px;
}

.message__sender > section {
  background-color: var(--ion-color-primary);
  color: white;
}

p{
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.message > section a:visited {
  color: black ;
}

.message > section a:link {
  color: black ;
}

.message__sender > section a:visited{
  color: white;
}

.message__sender > section a:link {
  color: white ;
}
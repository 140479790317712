.voicemessage {
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  width: fit-content;
  justify-content: space-between;
  max-width: 80%;
  margin-left: 15px;
  margin-right: auto;
  margin-bottom: 10px;
}

.voicemessage > section {
  display: flex;
  flex-direction: row;
  background-color: #f3f3f5;
  font-size: medium;
  padding: 10px;
  border-radius: 20px;
  margin: 10px;
  margin-left: 0px;
  margin-right: 0px;
  justify-content: center;
  align-items: flex-end;
}

ion-modal.fullscreen {
  --width: 100%;
  --height: 100%;
  --border-radius: 0;
}

.voicemessage > section > div > p {
  margin: 5px;
}

.voicemessage__audio {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}

.no-padding {
  --padding-start: 0 !important;
  --padding-end: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.ion-range {
  -webkit-padding-start: 7px !important;
  padding-inline-start: 7px !important;
  -webkit-padding-end: 7px !important;
  padding-inline-end: 7px !important;
  --knob-size: 14px !important;
  --height: 22px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.voicemessage__date {
  color: gray;
  position: absolute;
  font-size: 12px;
  bottom: -6px;
  left: 0;
  white-space: nowrap;
}

.voicemessage__date__outbound {
  right: 0;
  left: auto;
}

.voicemessage__timelabel {
  color: black;
  position: absolute;
  font-size: 11px;
  top: 21px;
  left: 30px;
  white-space: nowrap;
}

.voicemessage__sender {
  margin-left: auto;
  margin-right: 15px;
  align-items: end;
}

.voicemessage__sender > section {
  background-color: var(--ion-color-primary);
  color: white;
}

p{
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.voicemessage > section a:visited {
  color: black ;
}

.voicemessage > section a:link {
  color: black ;
}

.voicemessage__sender > section a:visited{
  color: white;
}

.voicemessage__sender > section a:link {
  color: white ;
}